import { useRef, useState } from "react";
import arrowIcon from "../right-arrow.svg";

function SoftwareEngineeringIntern() {
    const [expanded, setExpanded] = useState(false);
    const position = useRef<HTMLDivElement>(null);

    const toggleExpanded = () => {
        if (position.current && expanded) {
            position.current.scrollIntoView({ behavior: "smooth" });
        }

        setExpanded(!expanded);
    };

    return (
        <div className="position" ref={position}>
            <img
                className={`arrow ${expanded ? "up" : "down"}`}
                src={arrowIcon}
                alt="Arrow icon"
                onClick={toggleExpanded}
            />
            <p className="position-title">Software Engineering Intern - f/m/x</p>

            <div className="info">
                <div>
                    <div className="position-info">
                        <p>Place of employment:</p>
                        <p>Zagreb</p>
                    </div>
                    <div className="position-info">
                        <p>Application due date:</p>
                        <p>Open Until Filled</p>
                    </div>
                    <div className="position-info">
                        <p>Type of employment:</p>
                        <p>Full-time / Student job</p>
                    </div>
                </div>
            </div>

            <div className={`content ${expanded ? "expanded" : ""}`}>
                <div className="position-section">
                    <p>
                    </p>
                </div>
                <div className="position-section">
                    <p>KEY RESPONSIBILITIES:</p>
                    <p>
                        Supporting the development, troubleshooting and design of software source
                        code using modern software development languages and tools
                    </p>
                    <p>Participating in the full software development lifecycle</p>
                    <p>
                        Using development operations, collaboration and source control tools: e.g.,
                        Git
                    </p>
                    <p>
                        Supporting unit testing of code, including manipulation of data, for
                        analysis of system requirements
                    </p>
                </div>
                <div className="position-section">
                    <p>BASIC QUALIFICATIONS:</p>
                    <p>
                        Student of Computer Science/Computer Engineering/Mathematics or other
                        related field
                    </p>
                    <p>Basic knowledge of HTML and CSS</p>
                    <p>Basic knowledge of relational databases</p>
                    <p>
                        A strong, self-motivated desire to learn new programming languages, tools,
                        frameworks and techniques
                    </p>
                    <p>Focused on targets, deadline-oriented</p>
                </div>
                <div className="position-section">
                    <p>PREFERRED QUALIFICATIONS:</p>
                    <p>Basic knowledge of Linux</p>
                    <p>
                        Basic understanding of object-oriented programming techniques, data
                        structures and algorithms
                    </p>
                    <p>
                        Experience in working with any of the following technologies: C #, .NET
                        Core, SQL, Python, JavaScript, jQuery, Bootstrap
                    </p>
                    <p>Experience in using version control system technology (Git)</p>
                </div>
                <div className="position-section">
                    <p>
                        Send your CV and a short motivational letter to{" "}
                        <a className="mail-link no-undeline" href="mailto:info@project42.agency">
                            info@project42.agency
                        </a>{" "}
                        with the name of the position in the subject of the email.{" "}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SoftwareEngineeringIntern;
