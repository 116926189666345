import { Reducer } from "redux";
import { PageStateActionType, TOGGLE_CONTACT, TOGGLE_MENU } from "../actions/pageActions";

export interface PageState {
    menuOpen: boolean;
    contactOpen: boolean;
}

let initialState: PageState = {
    menuOpen: false,
    contactOpen: false
};

export const pageStateReducer: Reducer<PageState> = (
    state: PageState = initialState,
    action: PageStateActionType
): PageState => {
    switch(action.type) {
        case TOGGLE_CONTACT:
            return {...state, contactOpen: !state.contactOpen, menuOpen: false }
        case TOGGLE_MENU:
            return {...state, menuOpen: !state.menuOpen, contactOpen: false }
        default:
            return state;
    }
};