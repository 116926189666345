import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import "./ToastMessage.less";

export const ToastMessage = () => {
    const { showMessage, text, success } = useSelector((state: AppState) => state.toastMessage);

    return (
        <div className="toast-wrapper">
            <div className={`toast ${showMessage ? " show" : ""}${success ? "" : " error"}`}>
                {text}
            </div>
        </div>
    );
};
