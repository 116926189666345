import Section from "../Section";
import "./Legal.less";

function Legal() {
    return (
        <Section id="legal" className="legal-section" textColor="grey">
            <h1>Legal info</h1>

            <p>Project42</p>
            <p>
                The company is registered with the Commercial Court in Zagreb under registration
                number MBS 081125428.
            </p>
            <p>Base capital of the company of 20.000,00 has been paid in full.</p>
            <p>Members of the Management Board: Dino Mileta.</p>

            <br />

            <p>Address: Trg Drage Iblera 10</p>
            <p>Email: info@project42.agency</p>
            <p>Phone: (+385)954433487</p>
            <p>Bank account: 3024020061100846959</p>
            <p>IBAN: HR3024020061100846959</p>
            <p>Name of Bank: Erste &amp; Steiermarkische Bank d.d</p>
        </Section>
    );
}

export default Legal;
