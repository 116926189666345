import { Link } from "react-router-dom";
import "./ProjectCard.less";

const Fade = require("react-reveal/Fade");

export interface ProjectProps {
    name: string;
    ordinal: string;
    description: string;
    image: string;
    link?: string;
}

export const ProjectCard = (props: ProjectProps) => {
    return (
        <Fade up>
            <div id={props.name} className="project">
                <div className="project-summary">
                    <p>{props.ordinal}</p>
                    <h2>{props.name}</h2>
                    <p className="project-description">{props.description}</p>
                </div>

                {props.link &&
                    <Link to={props.link}>
                        <img src={props.image} alt="" />
                    </Link>
                }
                {!props.link &&
                    <img src={props.image} alt="" />
                }
            </div>
        </Fade>
    );
};
