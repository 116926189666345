import { combineReducers, createStore } from "redux";
import { PageState, pageStateReducer as pageState } from "./pageStateReducer";
import { toastMessageReducer as toastMessage, ToastMessageState } from "./toastMessageReducer";

export interface AppState {
    pageState: PageState;
    toastMessage: ToastMessageState;
}

export default function configureStore(initialState?: AppState) {
    const rootReducer = combineReducers<AppState>({
        pageState,
        toastMessage,
    });

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(rootReducer, initialState, ...enhancers);
}
