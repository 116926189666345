export const TOGGLE_MENU = "TOGGLE_MENU";
export const TOGGLE_CONTACT = "TOGGLE_CONTACT";

export type PageStateActionType =
    | ReturnType<typeof ToggleMenu>
    | ReturnType<typeof ToggleContact>;


export const ToggleMenu = () => {
    return { type: TOGGLE_MENU };
};

export const ToggleContact = () => {
    return { type: TOGGLE_CONTACT };
};