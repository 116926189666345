import Section from "../Section";
import "./Legal.less";

function Privacy() {
    return (
        <Section id="privacy" className="legal-section" textColor="grey">
            <h1>Privacy Policy</h1>

            <p>Effective date: January 11, 2022</p>
            <p>Last edit: February 14th, 2022</p>
            <p>
                Project 42. agency ("us", "we", or "our") operates the https://project42.agency/.
            </p>
            <p>
                We use your data to provide and improve the Service. By using the Service, you agree
                to the collection and use of information in accordance with this policy. Unless
                otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have
                the same meanings as in our Terms and Conditions.
            </p>

            <h2>Definitions</h2>
            <ul>
                <li>
                    <b>Service</b> - Service means the https://project42.agency/.
                </li>
                <li>
                    <b>Personal Data</b> - Personal Data means data about a living individual who
                    can be identified from those data (or from those and other information either in
                    our possession or likely to come into our possession).
                </li>
                <li>
                    <b>Usage Data</b> - Usage Data is data collected automatically either generated
                    by using the Service or from the Service infrastructure itself (for example, the
                    duration of a page visit).
                </li>
                <li>
                    <b>Cookies</b> - Cookies are small files stored on your device (computer or
                    mobile device) that allow us to improve your browsing experience.
                </li>
            </ul>

            <h2>Information Collection and Use</h2>
            <p>
                We collect several different types of information for various purposes to provide
                and improve our Service to you.
            </p>
            <b>Personal Data</b>
            <p>
                While using our Service, we may ask you to provide us with certain personally
                identifiable information that can be used to contact or identify you ("Personal
                Data"). Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>Cookies and Usage Data</li>
            </ul>
            <b>Usage Data</b>
            <p>
                We may also collect information that your browser sends whenever you visit our
                Service or when you access the Service by or through a mobile device ("Usage Data").
            </p>
            <p>
                This Usage Data may include information such as your computer's Internet Protocol
                address (e.g. IP address), browser type, browser version, the pages of our Service
                that you visit, the time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
            </p>
            <p>
                When you access the Service with a mobile device, this Usage Data may include
                information such as the type of mobile device you use, your mobile device unique ID,
                the IP address of your mobile device, your mobile operating system, the type of
                mobile Internet browser you use, unique device identifiers and other diagnostic
                data.
            </p>
            <b>Tracking &amp; Cookies Data</b>
            <p>
                We use cookies and similar tracking technologies to track the activity on our
                Service and we hold certain information.
            </p>
            <p>
                Cookies are files with a small amount of data which may include an anonymous unique
                identifier. Cookies are sent to your browser from a website and stored on your
                device. Other tracking technologies are also used such as beacons, tags and scripts
                to collect and track information and to improve and analyse our Service.
            </p>
            <p>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is
                being sent. However, if you do not accept cookies, you may not be able to use some
                portions of our Service.
            </p>

            <h2>What cookies do we use? How long do we store them?</h2>
            <ul>
                <li>
                    <b>Session Cookies</b> - These cookies are necessary to provide you with
                    services available through the Site and so that you can use some of its
                    features. These cookies are also used to allow for a coherent experience moving
                    between different pages. These are temporary cookie files, which are erased when
                    you close your browser.
                </li>
                <li>
                    <b>Persistent Cookies</b> - They are stored on a user's device to help remember
                    information, settings, preferences, or sign-on credentials that a user has
                    previously saved. This helps create a convenient and faster website experience.
                    These files stay in one of your browser's subfolders until you delete them
                    manually or your browser deletes them based on the duration period of 12 months
                    contained within the persistent cookie's file.
                </li>
                <li>
                    <b>Third-Party Cookies</b> - a third-party cookie is one that is placed on a
                    user’s hard disk by a Web site from a domain other than the one a user is
                    visiting. They are retained until you manually disable them in your Settings.
                </li>
            </ul>

            <h2>Use of Data</h2>
            <p>project42.agency uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>
                    To allow you to participate in interactive features of our Service when you
                    choose to do so
                </li>
                <li>To provide customer support</li>
                <li>
                    To gather analysis or valuable information so that we can improve our Service
                </li>
                <li>To monitor the usage of our Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>

            <h2>What are your rights?</h2>
            <ul>
                <li>the right to access data provided to us</li>
                <li>
                    the right to delete your information under certain circumstances (i.e., if the
                    data provided to us is no longer relevant for processing or was proven to be
                    unlawfully processed)
                </li>
                <li>the right to withdraw your consent</li>
            </ul>

            <h2>Contact Us</h2>
            <p>
                If you have any questions or petitions regarding this Privacy Policy, please contact
                us by email:{" "}
                <a className="mail-link" href="mailto:info@project42.agency">
                    info@project42.agency
                </a>
                . We will contact you as soon as possible and resolve your issue or query within 30
                days maximum.
            </p>

            <h2>Transfer of Data</h2>
            <p>
                Your information, including Personal Data, may be transferred to - and maintained on
                - computers located outside of your state, province, country or other governmental
                jurisdiction where the data protection laws may differ from those of your
                jurisdiction.
            </p>
            <p>
                If you are located outside Croatia and choose to provide information to us, please
                note that we transfer the data, including Personal Data, to Croatia and process it
                there.
            </p>
            <p>
                Your consent to this Privacy Policy followed by your submission of such information
                represents your agreement to that transfer.
            </p>
            <p>
                project42.agency will take all the steps reasonably necessary to ensure that your data
                is treated securely and in accordance with this Privacy Policy and no transfer of
                your Personal Data will take place to an organisation or a country unless there are
                adequate controls in place including the security of your data and other personal
                information
            </p>

            <h2>Disclosure of Data</h2>
            <b>Legal Requirements</b>
            <p>
                project42.agency may disclose your Personal Data in the good faith belief that such
                action is necessary to:
            </p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of project42.agency</li>
                <li>
                    To prevent or investigate possible wrongdoing in connection with the Service
                </li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            </ul>

            <h2>Security of Data</h2>
            <p>
                The security of your data is important to us but remember that no method of
                transmission over the Internet or method of electronic storage is 100% secure. While
                we strive to use commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
            </p>

            <h2>Service Providers</h2>
            <p>
                We may employ third party companies and individuals to facilitate our Service
                ("Service Providers"), provide the Service on our behalf, perform Service-related
                services or assist us in analysing how our Service is used.
            </p>
            <p>
                These third parties have access to your Personal Data only to perform these tasks on
                our behalfand are obligated not to disclose or use it for any other purpose.
            </p>

            <h2>Links to Other Sites</h2>
            <p>
                Our Service may contain links to other sites that are not operated by us. If you
                click on a third-party link, you will be directed to that third party's site. We
                strongly advise you to review the Privacy Policy of every site you visit.
            </p>
            <p>
                We have no control over and assume no responsibility for the content, privacy
                policies or practices of any third-party sites or services.
            </p>

            <h2>Children's Privacy</h2>
            <p>Our Service does not address anyone under the age of 18 ("Children").</p>
            <p>
                We do not knowingly collect personally identifiable information from anyone under
                the age of 18. If you are a parent or guardian and you are aware that your Child has
                provided us with Personal Data, please contact us. If we become aware that we have
                collected Personal Data from children without verification of parental consent, we
                take steps to remove that information from our servers.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any
                changes by posting the new Privacy Policy on this page.
            </p>
            <p>
                We will let you know via email and/or a prominent notice on our Service, prior to
                the change becoming effective and update the "effective date" at the top of this
                Privacy Policy
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes
                to this Privacy Policy are effective when they are posted on this page.
            </p>
            <p>Privacy Policy of https://project42.agency/</p>
        </Section>
    );
}

export default Privacy;
