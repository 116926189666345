import Section from "../../Section";
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";

import "../Work.less";
import projects from "./projects.json";

function AllProjects() {
    return (
        <Section id="project-list" textColor="white">
            <h1>Our projects</h1>

            <p>
                Switching back and forth between more technical and creative projects is our second
                nature. Here is a list of some of the projects that we have worked on so far.
            </p>

            {projects &&
                projects.map(project => {
                    return (
                        <ProjectCard
                            key={project.name}
                            name={project.name}
                            ordinal={project.ordinal}
                            description={project.description}
                            image={project.image}
                            link={project.link}
                        />
                    );
                })}
        </Section>
    );
}

export default AllProjects;
