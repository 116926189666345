interface ContactUsProps {
    borderColor: string;
    isExit?: boolean;
}

const ContactUs = ({ borderColor, isExit = false }: ContactUsProps) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15.49 14.75"
    >
        <title>ContactUs</title>
        {isExit ? (
            <path
                fill="#c03b2a"
                d="M625.17,507.21l1.8-1.8a0.8,0.8,0,1,0-1.13-1.13l-1.8,1.8-1.8-1.8a0.8,0.8,0,0,0-1.13,1.13l1.8,1.8-1.8,1.8a0.8,0.8,0,0,0,1.13,1.13l1.8-1.8,1.8,1.8A0.8,0.8,0,0,0,627,509Z"
                transform="translate(-616.29 -499.8)"
            />
        ) : (
            <circle fill="currentColor" cx="7.74" cy="7.34" r="2.71" />
        )}
        <path
            stroke={borderColor}
            d="M630.06,502l-4.25-1.81a4.36,4.36,0,0,0-3.4,0l-4.4,1.87a2.82,2.82,0,0,0-1.72,2.6v5.14a2.82,2.82,0,0,0,1.72,2.6l4.38,1.85a4.36,4.36,0,0,0,3.43,0l4.26-1.86a2.82,2.82,0,0,0,1.7-2.59v-5.19A2.82,2.82,0,0,0,630.06,502Zm0.92,2.6v5.19a2,2,0,0,1-1.21,1.85l-4.26,1.86a3.56,3.56,0,0,1-2.79,0l-4.38-1.85a2,2,0,0,1-1.23-1.86v-5.14a2,2,0,0,1,1.23-1.86l4.4-1.87a3.56,3.56,0,0,1,2.77,0l4.25,1.81A2,2,0,0,1,631,504.62Z"
            transform="translate(-616.29 -499.88)"
        />
    </svg>
);

export default ContactUs;
