export interface IProjectInfo {
    interestedSelected: string[];
    bugetSelected: string;
    aboutUsSelected: string;
    customerName: string;
    companyName: string;
    email: string;
    aboutProject: string;
}

export interface IShortMessage {
    name: string;
    email: string;
    aboutProject: string;
}

export const initShortMessage: IShortMessage = {
    name: "",
    email: "",
    aboutProject: "",
};
