import Section from "../../../Section";
import "./OurStory.less";

import storyImg from "./images/Story.jpg";

const Fade = require("react-reveal/Fade");

function OurStory() {
    return (
        <Section id="our-story" textColor="white" contactColor="red">
            <h1>Our Story</h1>

            <div className="story-container">
                <div className="img-container">
                    <Fade up>
                        <img src={storyImg} alt="" />
                    </Fade>
                </div>

                <div className="text-container">
                    <p>
                        After getting his PhD as one of the youngest students at his faculty, Dino
                        Mileta (Project42’s founding father), went on to work in various energy,
                        telecommunications and IT companies. Although he gathered invaluable
                        experience on his way, he had a dream of creating his own story. That is why
                        he started Uprise, Project42’s parent company. Since then, Uprise has become
                        more and more successful in developing software development and providing
                        consulting services in the energy sector.
                    </p>
                    <p>
                        All the while, Dino has been intrigued by using his technical knowledge in
                        more creative disciplines. That is how the idea of Project42 was born. We
                        have since been growing in size and experience. And although we are a young
                        company, we already have quite a few interesting projects under our belt and
                        are looking forward to adding new ones.
                    </p>
                </div>
            </div>
        </Section>
    );
}

export default OurStory;
