import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Section from "../../../Section";
import "./Quotes.less";

import quoteLogo from "./quotes_icon.svg";

interface Quote {
    content: string;
    name: string;
    credentials: string;
}

const quotes: Quote[] = [
    {
        content: "",
        name: "",
        credentials: "",
    },
];

function Quotes() {
    return (
        <Section id="quotes" textColor="white" contactColor="green">
            <h1>See what our clients say about us</h1>

            <Carousel
                className="quotes-carousel"
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                infiniteLoop
                interval={10000}
                autoPlay
            >
                {quotes.map((quote, index) => {
                    return (
                        <div key={quote.name + index} className="quote">
                            <div className="quote-row">
                                <img src={quoteLogo} alt="quote-logo" />
                                <p>{quote.content}</p>
                            </div>

                            <div className="quote-signature">
                                <div className="quote-name">{quote.name}</div>
                                <div>{quote.credentials}</div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </Section>
    );
}

export default Quotes;
