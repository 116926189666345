import Section from "../../Section";
import "../Careers.less";

function CareersHome() {
    return (
        <Section id="careers-home" textColor="grey" contactColor="green">
            <h1>Join our team & let's create great things together</h1>
        </Section>
    );
}

export default CareersHome;
