import { useDispatch, useSelector } from "react-redux";
import { ToggleMenu } from "../../actions/pageActions";
import { AppState } from "../../store/configureStore";
import './MenuButton.less';

function MenuButton() {
    const open = useSelector<AppState>(state => state.pageState.menuOpen);
    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(ToggleMenu())
    }

    return (
        <div id="menu-button" className={open ? "change" : ""} onClick={toggle}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>
    )
}

export default MenuButton;