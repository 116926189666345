import { useEffect, useState } from "react";
import { initShortMessage, IShortMessage } from "../../models/Contact";
import Section from "../Section";
import { postShortMessage } from "../../services/ContactService";
import "./Contact.less";
import { toast } from "../..";

function Contact() {
    const [shortMessage, setShortMessage] = useState<IShortMessage>(initShortMessage);
    const [sendingDisabled, setSendingDisabled] = useState(true);
    const [currentlySending, setCurrentlySending] = useState(false);

    const sendShortMessage = async () => {
        setCurrentlySending(true);
        const res = await postShortMessage(shortMessage);

        if (res.ok) {
            // Toast message sent success.
            setShortMessage(initShortMessage);
            toast("Your message was sent successfully.\nThank you!", true, 5000);
        } else {
            // Toast message failed.
            toast("It looks like something went wrong.\nPlase try again later!", true, 5000);
        }

        setCurrentlySending(false);
    };

    useEffect(() => {
        setSendingDisabled(!shortMessage.name || !shortMessage.email || !shortMessage.aboutProject);
    }, [shortMessage, setSendingDisabled]);

    return (
        <Section id="contact" textColor="grey" exitContact={true} logoLink={false}>
            <div id="welcome">
                <h1>We’d love to hear from you!</h1>
                <p>
                    Describe your project and leave us your contact info, we’ll get back to you
                    within 24 hours.
                </p>
            </div>
            <div id="info">
                <input
                    type="text"
                    placeholder="* What's your name?"
                    value={shortMessage.name}
                    onChange={e => {
                        setShortMessage({ ...shortMessage, name: e.target.value });
                    }}
                />
                <input
                    type="text"
                    placeholder="* What's your E-mail?"
                    value={shortMessage.email}
                    onChange={e => setShortMessage({ ...shortMessage, email: e.target.value })}
                />
                <textarea
                    rows={8}
                    placeholder="* Send us a message :)"
                    value={shortMessage.aboutProject}
                    onChange={e =>
                        setShortMessage({ ...shortMessage, aboutProject: e.target.value })
                    }
                ></textarea>

                <div id="send-btn">
                    <div className="tooltip-wrapper">
                        <div
                            hidden={!sendingDisabled || currentlySending}
                            className={`tooltip ${currentlySending ? "force-visible" : ""}`}
                        >
                            Please fill all of the fields above before sending.
                        </div>
                        <div hidden={!currentlySending} className="tooltip">
                            Your message is being sent!
                        </div>
                        <h2
                            className={`${sendingDisabled || currentlySending ? "disabled" : ""}`}
                            onClick={sendShortMessage}
                        >
                            Send
                        </h2>
                    </div>
                </div>
            </div>

            {/**
                <div id="what-next">
                    <h3>What happens next?</h3>
                    <ul>
                        <li>We will reply within 24 hours</li>
                        <li>We will set up a meeting where you can get all relevant info</li>
                        <li>You will get a price estimation</li>
                        <li>We will build great products together</li>
                    </ul>
                </div>
             */}

            <div id="additional-info-bar">
                <div className="line-wrap">
                    <hr />
                </div>
                <p>
                    <a className="mail-link no-underline" href="mailto:info@project42.agency">
                        info@project42.agency
                    </a>
                </p>
                <p>Project 42 d.o.o</p>
                <p>Petrova 15, 10 000 Zagreb</p>
            </div>
        </Section>
    );
}

export default Contact;
