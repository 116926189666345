import { HashLink as Link } from "react-router-hash-link";
import complexSolutionsLogo from "./images/Complex solutions_icon.svg";
import designLogo from "./images/Design_icon.svg";
import mobileDevelopmentLogo from "./images/Mobile Development_icon.svg";
import animationLogo from "./images/2D - 3D Animation_icon.svg";
import vrLogo from "./images/AR-VR_icon.svg";
import reportingAndDataVisIcon from "./images/Reporting visualisation_icon.svg";
import Section from "../../../Section";
import "./WhatWeDo.less";

const Fade = require("react-reveal/Fade");

function WhatWeDo() {
    return (
        <Section id="about" textColor="white">
            <h1>What we do</h1>
            <p>
                Whether you are a start-up, an established business or looking to launch your own
                product, Project42 can help you with various services. It does not matter if your
                project is only an idea on a piece of paper or a project that just got stuck, we
                rock all product lifecycle stages and can give you the push you need. Read on for
                more info.
            </p>
            <h3>Our Services</h3>

            <div className="items-container">
                <Fade up>
                    <div className="item">
                        <img src={mobileDevelopmentLogo} alt="" />
                        <Link to="/services#software-development">
                            <h3>Custom software development</h3>
                        </Link>
                        <p>
                            Overcome unique challenges and build software that gets you where you
                            want to be.
                        </p>
                    </div>
                </Fade>
                <Fade up>
                    <div className="item">
                        <img src={designLogo} alt="" />
                        <Link to="/services#design">
                            <h3>Design &amp; UI/UX</h3>
                        </Link>
                        <p>
                            Inspired and user-friendly designs are what we are all about. Revamping
                            existing apps or inventing something original for a new one is our
                            expertise.
                        </p>
                    </div>
                </Fade>
                <Fade up>
                    <div className="item">
                        <img src={animationLogo} alt="" />
                        <Link to="/services#2D-3D-solutions">
                            <h3>2D / 3D Solutions</h3>
                        </Link>
                        <p>
                            Anything you can imagine, we can bring to life. Our 3D animations add a
                            touch of magic to any cartoon, mobile game or commercial.
                        </p>
                    </div>
                </Fade>
                <Fade up>
                    <div className="item">
                        <img src={reportingAndDataVisIcon} alt="" />
                        <Link to="/services#reporting-services-and-data-visualization">
                            <h3>
                                Reporting services &amp; <br />
                                data visualisation
                            </h3>
                        </Link>
                        <p>
                            Extract the important data and make it understandable and visually
                            pleasing.
                        </p>
                    </div>
                </Fade>
                <Fade up>
                    <div className="item">
                        <img src={complexSolutionsLogo} alt="" />
                        <Link to="/services#complex-solutions">
                            <h3>Complex solutions</h3>
                        </Link>
                        <p>
                            Whether you are looking to build a cloud-based app or just modernize
                            your existing IT architecture, we have a solution for you.
                        </p>
                    </div>
                </Fade>
                <Fade up>
                    <div className="item">
                        <img src={vrLogo} alt="" />
                        <Link to="/services#vr-ar">
                            <h3>AR / VR</h3>
                        </Link>
                        <p>
                            Our VR and AR are as close to magic as it gets. We make products stand
                            out through unique visual experiences.
                        </p>
                    </div>
                </Fade>
            </div>
        </Section>
    );
}

export default WhatWeDo;
