import MenuButton from '../MenuButton/MenuButton';
import './RightAside.less';

export const RightAside = () => {
    return (
        <aside className="aside aside-right">
            <MenuButton/>
        </aside>
    );
};
