import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { ToggleContact, ToggleMenu } from "../../actions/pageActions";
import { HashLink as Link } from "react-router-hash-link";
import Section from "../Section";
import "./Menu.less";

interface NavItem {
    name: string;
    link?: string;
}

const menuItems: NavItem[][] = [
    [
        { name: "Home", link: "/" },
        { name: "About", link: "/about" },
        { name: "Work", link: "/work" },
    ],
    [
        { name: "Services", link: "/services" },
        { name: "Careers", link: "/careers" },
        { name: "Contact" },
    ],
];

function Menu() {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(ToggleMenu());
    };

    const openContact = () => {
        dispatch(ToggleContact());
    };

    const navOption = (option: NavItem): ReactNode => {
        let onClick = option.name === "Contact" ? openContact : close;

        if (option.link) {
            return (
                <Link key={option.name} to={option.link}>
                    <h1 onClick={onClick}>{option.name}</h1>
                </Link>
            );
        } else {
            return (
                <h1 key={option.name} onClick={onClick}>
                    {option.name}
                </h1>
            );
        }
    };

    return (
        <Section id="menu-container" textColor="white" logoColor="grey" logoLink={false}>
            <div id="options-container">
                {menuItems.map((items, i) => (
                    <div key={i} className="column">
                        {items.map(item => navOption(item))}
                    </div>
                ))}
            </div>
            <div id="social-bar">
                <a
                    href="https://www.facebook.com/project42.agency"
                    target="_blank"
                    rel="noreferrer"
                >
                    Facebook
                </a>
                <a
                    href="https://www.instagram.com/project42.agency/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Instagram
                </a>
                <a
                    href="https://www.linkedin.com/company/project42agency/?viewAsMember=true"
                    target="_blank"
                    rel="noreferrer"
                >
                    LinkedIn
                </a>
            </div>
        </Section>
    );
}

export default Menu;
