import React from "react";
import { Link } from "react-router-dom";
import Section from "../../../Section";
//import BuildProjectWizard from "../../../../components/BuildProjectWizard/BuildProjectWizard";
import "./Home.less";

function Home() {
    //const [buildProductOpen, setBuildProductOpen] = React.useState<boolean>(false);

    return (
        <Section id="home" textColor="grey">
            <h1>There are great things to be seen in this most unusual agency...</h1>
            <div className="row">
                <span>*</span>
                <div>
                    <p>
                        We are a creative development agency that takes you from ideas to product
                        delivery. Our skilled team will guide you through all product lifecycle
                        stages. Drop us a message and let’s begin!
                    </p>
                    {/**
                     <Link
                         to="#"
                         className={buildProductOpen ? "non-underlined" : ""}
                         onClick={() => setBuildProductOpen(!buildProductOpen)}
                     >
                         Build a product
                     </Link>
                     */}
                </div>
            </div>

            {/*
                <div className="wizard-container">
                    <BuildProjectWizard open={buildProductOpen} />
                </div>
            */}
        </Section>
    );
}

export default Home;
