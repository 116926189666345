import complexSolutionsLogo from "../../Landing/Sections/WhatWeDo/images/Complex solutions_icon.svg";
import designLogo from "../../Landing/Sections/WhatWeDo/images/Design_icon.svg";
import mobileDevelopmentLogo from "../../Landing/Sections/WhatWeDo/images/Mobile Development_icon.svg";
import animationLogo from "../../Landing/Sections/WhatWeDo/images/2D - 3D Animation_icon.svg";
import vrLogo from "../../Landing/Sections/WhatWeDo/images/AR-VR_icon.svg";
import reportingAndDataVisIcon from "../../Landing/Sections/WhatWeDo/images/Reporting visualisation_icon.svg";
import { HashLink as Link } from "react-router-hash-link";
import Section from "../../Section";

function ServicesList() {
    return (
        <Section id="services-list" textColor="white" contactColor="green">
            <h1>Services</h1>
            <div className="item">
                <img src={mobileDevelopmentLogo} alt="" />
                <Link to="/services#software-development">
                    <h3>
                        Custom Software <br />
                        Development
                    </h3>
                </Link>
                <ul>
                    <li>Enterprise software</li>
                    <li>Mobile apps</li>
                    <li>Web apps</li>
                </ul>
            </div>

            <div className="item">
                <img src={designLogo} alt="" />
                <Link to="/services#design">
                    <h3>Design</h3>
                </Link>

                <ul>
                    <li>UX/UI</li>
                    <li>Visual identity design</li>
                    <li>Mockups &amp; Wireframes</li>
                </ul>
            </div>

            <div className="item">
                <img src={animationLogo} alt="" />
                <Link to="/services#2D-3D-solutions">
                    <h3>2D / 3D Solutions</h3>
                </Link>

                <ul>
                    <li>Mobile games</li>
                    <li>3D Animation</li>
                    <li>Motion Graphics</li>
                    <li>Holographic projections</li>
                </ul>
            </div>

            <div className="item">
                <img src={reportingAndDataVisIcon} alt="" />
                <Link to="/services#reporting-services-and-data-visualization">
                    <h3>
                        Reporting Services &amp;
                        <br /> Data Visualization
                    </h3>
                </Link>

                <ul>
                    <li>Reporting tools</li>
                    <li>Data cleansing</li>
                    <li>Data management</li>
                    <li>Data visualization</li>
                </ul>
            </div>

            <div className="item">
                <img src={complexSolutionsLogo} alt="" />
                <Link to="/services#complex-solutions">
                    <h3>Complex Solutions</h3>
                </Link>

                <ul>
                    <li>Cloud apps</li>
                    <li>Data storage</li>
                    <li>Data analysis</li>
                    <li>Enterprise Application Integration</li>
                    <li>Data Integration</li>
                    <li>Optimization</li>
                </ul>
            </div>

            <div className="item">
                <img src={vrLogo} alt="" />
                <Link to="/services#vr-ar">
                    <h3>VR / AR</h3>
                </Link>

                <ul>
                    <li>Custom apps</li>
                    <li>Games</li>
                </ul>
            </div>
        </Section>
    );
}

export default ServicesList;
