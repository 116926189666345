import ServicesHome from "./Sections/ServicesHome";
import ServicesList from "./Sections/ServicesList";
import ServicesDetailed from "./Sections/ServicesDetailed";
import "./Services.less";

function Services() {
    return (
        <div id="services">
            <ServicesHome />
            <ServicesList />
            <ServicesDetailed />
        </div>
    );
}

export default Services;
