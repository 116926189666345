import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RightAside } from "../../components/RightAside/RightAside";
import { AppState } from "../../store/configureStore";
import { ToastMessage } from "../../components/ToastMessage/ToastMessage";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";
import { CookieConsentPrompt } from "../../components/CookieConsentPrompt/CookieConsentPrompt";
import "./Layout.less";

export const Layout = (props: any) => {
    const [scroll, setScroll] = useState<number>(0);
    const [scrollDirection, setScrollDirection] = useState<string>("up");
    const menuOpen = useSelector<AppState>(state => state.pageState.menuOpen);
    const contactOpen = useSelector<AppState>(state => state.pageState.contactOpen);

    useEffect(() => {
        if (menuOpen || contactOpen) {
            setScrollDirection("up");
        }
    }, [menuOpen, contactOpen]);

    const getClassName = () => {
        let clases = `scroll-${scrollDirection}`;
        if (menuOpen) {
            clases += " menu-open";
        }
        if (contactOpen) {
            clases += " contact-open";
        }
        return clases;
    };

    const handleScroll = (e: any) => {
        let currentScroll = e.target.scrollTop;
        let currentScrollDirection = scrollDirection;
        if (currentScroll !== scroll) {
            currentScrollDirection = currentScroll > scroll ? "down" : "up";
        }
        if (currentScrollDirection !== scrollDirection) {
            setScrollDirection(currentScrollDirection);
        }
        setScroll(currentScroll);
    };

    return (
        <div id="layout" className={getClassName()}>
            <ToastMessage />
            <CookieConsentPrompt />
            <div id="scrollable" onScroll={handleScroll}>
                <main>{props.children}</main>
                <Footer />
            </div>
            <nav>
                <Menu />
            </nav>
            <div id="contact-container" onScroll={handleScroll}>
                <Contact />
            </div>
            <RightAside />
        </div>
    );
};
