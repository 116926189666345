import Section from "../../../Section";
import "./WhoWeAre.less";

const Fade = require("react-reveal/Fade");

function WhoWeAre() {
    return (
        <Section id="team" textColor="white" contactColor="red">
            <h1>Who we are</h1>
            <p>
                Project42 is a development and digital experience agency. Our versatile team of tech-curious experts
                transform ideas into successful products. What brings us together is a common
                passion for all things tech. Throw a project at us and you’ll see our developers,
                product and project teams put their heads together to deliver smart and attractive
                solutions.
            </p>

            <div id="team-images">
                <div>
                    <h3>In a sea of digital agencies, a few things set us apart...</h3>
                    <div>
                        <ul>
                            <li>
                                we don’t just think outside the box, we say there should be no box
                                at all
                            </li>
                            <li>
                                if there is a new tech advancement, we will try it, test it,
                                implement it (or not) and use it to your advantage
                            </li>
                            <li>we offer the perfect mix of creativity and reliability</li>
                            <li>we have NEVER had a client who was less than thrilled</li>
                            <li>
                                we have an awesome team who are not only super skilled but great
                                people too
                            </li>
                            <li>
                                we put the client and their needs at the centre of our operations.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default WhoWeAre;
