import Section from "../../Section";
import SoftwareEngineeringIntern from "../Positions/SoftwareEngineeringIntern";

import "../Careers.less";

function CareersPositions() {
    return (
        <Section id="careers-positions" textColor="white" contactColor="yellow">
            <h1>Open Positions</h1>
            <SoftwareEngineeringIntern />

            <p id="careers-open-application">
                Don't see your position here? <br />
                Send us an open application with your CV and a short motivational letter to{" "}
                <a className="mail-link" href="mailto:info@project42.agency">
                    info@project42.agency
                </a>
            </p>
        </Section>
    );
}

export default CareersPositions;
