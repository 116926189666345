import CareersHome from "./Sections/CareersHome";
import CareersPositions from "./Sections/CareersPositions";
import CareersGeneric from "./Sections/CareersGeneric";

import "../Section.less";
import "./Careers.less";

function Careers() {
    return (
        <div id="careers">
            <CareersHome />
            <CareersPositions />
            <CareersGeneric />
        </div>
    );
}

export default Careers;
