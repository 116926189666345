import WorkHome from "./Sections/WorkHome";
import AllProjects from "./Sections/AllProjects";

function Work() {
    return (
        <div id="work">
            <WorkHome />
            <AllProjects />
        </div>
    );
}

export default Work;
