import Section from "../../../Section";
import { ProjectCard } from "../../../../components/ProjectCard/ProjectCard";
import { Link } from "react-router-dom";

import "./OurProjects.less";
import projects from "./selectedProjects.json";

function OurProjects() {
    return (
        <Section id="work" textColor="white" contactColor="green">
            <h1>Our projects</h1>
            <p>
                With the diverse skill set that our team has, we get to work on projects in a vast
                scope of industries. Take a look at some of them:
            </p>

            <div id="projects-container">
                {projects.map(project => {
                    return (
                        <ProjectCard
                            key={project.name}
                            name={project.name}
                            ordinal={project.ordinal}
                            description={project.description}
                            image={project.image}
                            link={project.link}
                        />
                    );
                })}
            </div>

            <h3>
                <Link to="/work">See more</Link>
            </h3>
        </Section>
    );
}

export default OurProjects;
