import { IProjectInfo, IShortMessage } from "../models/Contact";

export async function postProjectInfo(projectInfo: IProjectInfo) {
    return await fetch("/api/Contacts", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(projectInfo),
    });
}

export async function postShortMessage(shortMessage: IShortMessage) {
    return await fetch("/api/Contacts/shortmessage", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(shortMessage),
    });
}
