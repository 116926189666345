import Section from "../../Section";
import slashIcon from "./Slash.svg";
import { ReactComponent as ProcessIcon } from "./Our process.svg";

function ServicesDetailed() {
    return (
        <Section id="services-detailed" textColor="white" contactColor="red">
            <div id="software-development" className="service">
                <div className="title-container">
                    <p>01</p>
                    <div className="name-container">
                        <h3 className="service-name">
                            Custom Software <br /> Development
                        </h3>
                        <img src={slashIcon} alt="" />
                    </div>
                    <h3>
                        We take you from <br />
                        ideas to great products.
                    </h3>
                </div>
                <div className="content">
                    <div className="services-list">
                        <p>Enterprise software</p>
                        <p>Mobile apps</p>
                        <p>Web apps</p>
                    </div>
                    <div className="services-description">
                        <p>
                            In need of an app? A piece of software to solve a problem in your
                            company? Look no further, we got you covered!
                        </p>
                        <p>
                            You don’t need to be a developer or know fancy IT words. It’s enough to
                            have an idea or a problem you need help with and we’ll do the rest.
                        </p>
                        <p>
                            How does it work? Our team will talk to you until we understand what
                            your needs are and how to approach them best. We will explore all our
                            options together with you. We will analyze the competition, propose
                            various approaches and see what works best for you. With the input of
                            our designers, our talented team of developers comes into play and do
                            their magic to produce intuitive, user-friendly, efficient products. We
                            test them to make sure they pass the highest standards and then we help
                            you with maintenance. See? You’re in good hands with us.
                        </p>
                    </div>
                </div>
                <div className="content">
                    <div className="services-list">
                        <b>Our process</b>
                    </div>
                    <div className="services-description">
                        <ProcessIcon />
                    </div>
                </div>
            </div>

            <div id="design" className="service">
                <div className="title-container">
                    <p>02</p>
                    <div className="name-container">
                        <h3 className="service-name">Design</h3>
                        <img src={slashIcon} alt="" />
                    </div>
                    <h3>
                        Creative and fun. <br /> Can be done!
                    </h3>
                </div>
                <div className="content">
                    <div className="services-list">
                        <p>UX/UI</p>
                        <p>Visual identity design</p>
                        <p>Mockups &amp; Wireframes</p>
                    </div>
                    <div className="services-description">
                        <p>
                            Our multitalented creative department produces graphic treats of all
                            kinds. We aim at practical and user-friendly, yet fresh and edgy because
                            we know you want to stand out.
                        </p>
                        <p>
                            Whether you need visual identity elaboration or help with your UI/UX, we
                            are here to deliver contemporary designs. Maybe you need help building a
                            new product or just want to modernize a existing one that doesn’t quite
                            meet your users’ needs. Whichever the case, we start by examining the
                            functionalities of your product and think about ways to present them so
                            that they are intuitive, visually pleasing and pleasant to use.
                        </p>
                    </div>
                </div>
            </div>

            <div id="2D-3D-solutions" className="service">
                <div className="title-container">
                    <p>03</p>
                    <div className="name-container">
                        <h3 className="service-name">
                            2D / 3D <br /> Solutions
                        </h3>
                        <img src={slashIcon} alt="" />
                    </div>
                    <h3>
                        Who knows where <br />
                        our minds take you.
                    </h3>
                </div>
                <div className="content">
                    <div className="services-list">
                        <p>Mobile games</p>
                        <p>3D Animation</p>
                        <p>Motion Graphics</p>
                        <p>Holographic projections</p>
                    </div>
                    <div className="services-description">
                        <p>
                            Our multidisciplinary design team encompasses experts in the fields of
                            animation, 2D and 3D – modelling and mobile games.
                        </p>
                        <p>
                            Through 3D animation executed in Blender, teamed with motion graphics
                            and character design, we bring captivating imagery and entertaining
                            stories to life. We specialize in character design, rig, animation,
                            texturing, environment design, rendering and post-production.
                        </p>
                        <p>
                            In a sea of multimedia and content overload we suggest trying something
                            original to stand out. Our versatility and open mind take us in
                            different directions and fun projects, such as kids’ mobile games and
                            interactive holograms created in Unity. Our holographic projections can
                            be used in a multitude of different settings – from exhibitions to
                            weddings and marketing events. They guarantee a unique experience bound
                            to make your audience go wow.
                        </p>
                    </div>
                </div>
            </div>

            <div id="reporting-services-and-data-visualization" className="service">
                <div className="title-container">
                    <p>04</p>
                    <div className="name-container">
                        <h3 className="service-name">
                            Reporting Services &amp;
                            <br />
                            Data Visualization
                        </h3>
                        <img src={slashIcon} alt="" />
                    </div>
                    <h3>
                        Let your data <br />
                        do the talking.
                    </h3>
                </div>
                <div className="content">
                    <div className="services-list">
                        <p>Reporting tools</p>
                        <p>Data cleansing</p>
                        <p>Data management</p>
                        <p>Data visualization</p>
                    </div>
                    <div className="services-description">
                        <p>
                            Ever feel like the data you deal with is overwhelming? Like endless
                            spreadsheets are just adding to the confusion? We feel the same way.
                            Wouldn’t it be great to integrate all your data sources and extract the
                            important stuff to reveal opportunities? We can create powerful custom
                            tools for you that compile relevant data and produce reports of any
                            format (text-based or visual) that are easy to understand and help you
                            make better decisions.
                        </p>
                    </div>
                </div>
            </div>

            <div id="complex-solutions" className="service">
                <div className="title-container">
                    <p>05</p>
                    <div className="name-container">
                        <h3 className="service-name">
                            Complex <br />
                            Solutions
                        </h3>
                        <img src={slashIcon} alt="" />
                    </div>
                    <h3>
                        Work to your <br />
                        advantage.
                    </h3>
                </div>
                <div className="content">
                    <div className="services-list">
                        <p>Cloud apps</p>
                        <p>Data storage</p>
                        <p>Data analysis</p>
                    </div>
                    <div className="services-description">
                        <h3>Cloud-based computing</h3>
                        <p>
                            Cloud technology has not been around for long, but already more and more
                            organizations are embracing it. In simple words. It is the delivery of
                            services, such as software, data, storage, servers and many more via the
                            Internet (“the cloud”).
                            <br />
                            Why is it taking the world by storm? Because it offers a lot of
                            benefits: cost reduction, reliability, security, enhanced performance
                            and easier scaling, to name just a few.
                        </p>
                        <p>We use cloud computing in various ways to help businesses:</p>
                        <ul>
                            <li>
                                to build cloud-based apps that are easy to deploy, maintain and are
                                safe
                            </li>
                            <li>to build and maintain your databases</li>
                            <li>
                                to streamline all your data sources to one place for easier data
                                management.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="content">
                    <div className="services-list">
                        <p>Enterprise application integration</p>
                        <p>Data integration</p>
                        <p>Optimization</p>
                    </div>
                    <div className="services-description">
                        <h3>System integration</h3>
                        <p>
                            Your business will grow and evolve. Sometimes your IT landscape won’t
                            catch up - it will consume your energy by being disintegrated, slow and
                            dysfunctional.
                        </p>
                        <p>
                            The good news is – we can help you with that. We start by analyzing your
                            current system and defining its weak points. We eliminate them by either
                            connecting your subsystems into one fully functioning system or by
                            creating custom software that bridges gaps or replaces outdated
                            elements.
                        </p>
                        <p>
                            This will result in quick and efficient communication, better
                            productivity, great overview of your activities and informed
                            decision-making.
                        </p>
                    </div>
                </div>
            </div>

            <div id="vr-ar" className="service">
                <div className="title-container">
                    <p>06</p>
                    <div className="name-container">
                        <h3 className="service-name">VR / AR</h3>
                        <img src={slashIcon} alt="" />
                    </div>
                    <h3>
                        When reality <br />
                        gets boring.
                    </h3>
                </div>
                <div className="content">
                    <div className="services-list">
                        <p>Custom Apps</p>
                        <p>Games</p>
                    </div>
                    <div className="services-description">
                        <p>
                            Augmented and virtual reality are not new, but as tech giants such as
                            Google, Samsung and Microsoft take an interest in delivering new
                            solutions, we are now witnessing AR and VR becoming the next big thing.
                            Both have amazing possibilities of altering our perception, with VR
                            being more dramatic in the sense that it transposes you to another place
                            completely using special glasses. On the other hand, AR has a more
                            commercial use as it just adds layers of content visible through the
                            lens of your camera. The applications of both are immense – they are now
                            being used from education to military drills to medicine. The options
                            are boundless.
                        </p>
                        <p>
                            We make custom apps that explore this new technology and make your
                            product pop. Forget standard marketing strategies, add dinosaurs and
                            aliens in the mix. Or whatever rocks your boat.
                        </p>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default ServicesDetailed;
