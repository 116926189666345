import Section from "../../Section";
import "../Work.less";

function WorkHome() {
    return (
        <Section id="work-home" textColor="grey">
            <h1>
                Ideas are great. Finished products are even better. See how we’ve helped others make
                that leap.
            </h1>
        </Section>
    );
}

export default WorkHome;
