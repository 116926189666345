import TeamHome from "./Sections/TeamHome/TeamHome";
import AboutUs from "./Sections/AboutUs/AboutUs";
import OurStory from "./Sections/OurStory/OurStory";
import Quotes from "./Sections/Quotes/Quotes";

function Team() {
    return (
        <>
            <TeamHome />
            <AboutUs />
            <OurStory />
            <Quotes />
        </>
    );
}

export default Team;
