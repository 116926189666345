import Section from "../../Section";
import "../Services.less";

function ServicesHome() {
    return (
        <Section id="services-home" textColor="grey">
            <h1>
                Where great
                <br />
                things await
            </h1>
        </Section>
    );
}

export default ServicesHome;
