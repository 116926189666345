import Section from "../../Section";
import "../Careers.less";

function CareersGeneric() {
    return (
        <Section id="careers-generic" textColor="white" contactColor="green">
            <h2>
                If our fun projects have you curious, come join team Project42 <br />
                Do so especially if you...
            </h2>

            <ul>
                <li>want to work on dynamic and interesting projects</li>
                <li>want to try out new gadgets and learn new technologies</li>
                <li>enjoy a relaxed and friendly atmosphere</li>
                <li>want a merit-based approach to promotions and bonuses</li>
                <li>want to develop your skills with courses, books, conferences</li>
                <li>enjoy cake and beer... not necessarily together</li>
                <li>want to work in a brand new, cool office in the city center</li>
            </ul>
        </Section>
    );
}

export default CareersGeneric;
