import Home from "./Sections/Home/Home";
import OurProjects from "./Sections/OurProjects/OurProjects";
import WhatWeDo from "./Sections/WhatWeDo/WhatWeDo";
import WhoWeAre from "./Sections/WhoWeAre/WhoWeAre";

function Landing() {
  return (
    <>
        <Home />
        <WhatWeDo />
        <WhoWeAre />
        <OurProjects />
    </>
  );
}

export default Landing;
